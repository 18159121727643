import React from 'react';
import cx from 'classnames';
import styles from './Button.module.css';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  size?: string;
  resetClass?: boolean;
}
export const WhiteOutlineButton = ({
  children,
  disabled = false,
  type = 'button',
  onClick,
  className,
  size,
  resetClass,
  ...props
}: Props) => {
  const defaultClasses = resetClass ? '' : 'f18 fw7 ph32 ttu br3 w-100 pointer';
  const buttonClasses = cx(
    defaultClasses,
    {
      'white ba b--white bw1 pointer ': !disabled,
      'boulder bg-alto b--alto ba bw1 not-allowed': disabled,
      pv12: !size,
      [styles.h48]: !size,
      pv8: size === 'medium',
      [styles.h40]: size === 'medium',
      [styles.pv10]: disabled,
    },
    styles.whiteOutlineButtonClass,
    styles.h48,
    className,
  );
  return (
    <button className={buttonClasses} disabled={disabled} type={type} onClick={onClick} {...props}>
      {children}
    </button>
  );
};
