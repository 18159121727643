import React from 'react';
import cx from 'classnames';
import styles from './Button.module.css';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  resetPadding?: boolean;
  className?: string;
  size?: string;
  title?: string;
  'aria-label'?: string;
  heightAuto?: boolean;
}

export const OutlineButton = ({
  children,
  disabled = false,
  type = 'button',
  onClick,
  className,
  resetPadding,
  size,
  heightAuto = false,
  ...props
}: Props) => {
  const buttonClasses = cx(
    'fw7 ttu br3 w-100 pointer',
    {
      'red bg-white ba b--red bw1 pointer ': !disabled,
      'boulder bg-white b--alto ba bw1 not-allowed': disabled,
      ph32: !resetPadding,
      pv12: !size && !heightAuto,
      [styles.h48]: !size,
      pv8: size === 'medium' || size === 'small',
      [styles.h40]: size === 'medium' && !heightAuto,
      [styles.h33]: size === 'small' && !heightAuto,
      [styles.h48]: !heightAuto,
      [styles.pv10]: disabled,
      f18: !size,
      f16: size === 'medium',
      f14: size === 'small',
    },
    styles.btnHoverOutline,
    className,
  );
  return (
    <button className={buttonClasses} disabled={disabled} type={type} onClick={onClick} {...props}>
      {children}
    </button>
  );
};
