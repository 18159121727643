import React from 'react';
import { Layout } from '../components/Layout';
import { SelectAdType } from '../components/SelectAdType';

const selectAdType = () => {
  return (
    <div className="vh-100">
      <Layout>
        <SelectAdType />
      </Layout>
    </div>
  );
};

export default selectAdType;
