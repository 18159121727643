import React, { KeyboardEvent } from 'react';
import cx from 'classnames';
import Lottie from 'react-lottie';
import loader from '../Animation/loader.json';
import styles from './Button.module.css';

interface Props {
  green?: boolean;
  loading?: boolean;
  disabled?: boolean;
  resetClass?: boolean;
  classButton?: string;
  size?: 'big' | 'medium';
  type?: 'submit' | 'reset' | 'button';
  onClick?: (e?: any) => void;
  onKeyDown?: (event: KeyboardEvent<Element>) => void;
  children: React.ReactNode;
  'aria-label'?: string;
  title?: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
};

export const PrimaryButton = ({
  size = 'big',
  loading = false,
  disabled = false,
  type,
  green,
  onClick,
  children,
  resetClass,
  classButton,
  ...props
}: Props) => {
  const handleBtn = loading ? <Lottie options={defaultOptions} height={28} width={28} /> : children;
  const buttonClasses = cx(
    'w-100 br3 bn',
    {
      f16: size === 'medium',
      pv8: size === 'medium' && !resetClass,
      pv12: size === 'big' && !resetClass,
      [styles.h48]: size === 'big',
      [styles.h40]: size === 'medium',
      [styles.btnHover]: !green,
      [styles.btnHoverGreen]: green,
      'ph32 fw7 ttu': !resetClass,
      'f14 f18-m f18-l': size === 'big',
      'boulder bg-alto not-allowed': disabled,
      'gradient-torch-red not-allowed': loading,
      'gradient-green-grass green-shadow-hover': green,
      'gradient-torch-red red-shadow-hover white pointer': !disabled || loading,
    },
    classButton,
  );
  return (
    <button
      type={type}
      onClick={onClick}
      className={buttonClasses}
      disabled={disabled || loading}
      {...props}
    >
      {handleBtn}
    </button>
  );
};
