import React, { useState } from 'react';
import cx from 'classnames';
import { StaticQuery, graphql, navigate } from 'gatsby';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { Collapse } from 'react-collapse';
import { Footer } from '../../modules/Footer';
import { Nodes, Category, DirectusCategoria } from '../../types/graphql';
import { lowerNoAccent } from '../../utils/functions';
import { Icon } from '../Icons';
import { Topbar } from '../Topbar';
import { CardItem } from './components/CardItem';
import styles from './style.module.css';

interface Props {
  data: Nodes<Category>;
}
export const RenderSelectAdType = ({ data }: Props) => {
  const fixedCards = data.slice(0, 2);
  const collapsedCards = data.slice(2, data.length);

  const [expanded, setExpanded] = useState(false);
  const clickCards = (name: string) => navigate(`/cadastrar-anuncio/${lowerNoAccent(name)}`);
  const getFixedCards = () => {
    return map(fixedCards, item => {
      const { name, icon, icon_hover } = item;
      const onClick = () => clickCards(name);
      return (
        <CardItem onClick={onClick} key={name} name={name} icon={icon} iconHover={icon_hover} />
      );
    });
  };

  const getCollapsedCards = () => {
    const conditionValues = ['Carga', 'Ônibus', 'Agrícola', 'Náutica'];
    const cardsToShow = filter(collapsedCards, item => conditionValues.includes(item.name));
    return map(cardsToShow, item => {
      const { name, icon, icon_hover } = item;
      const onClick = () => clickCards(name);
      return (
        <CardItem
          onClick={onClick}
          key={name}
          name={name}
          icon={icon}
          iconHover={icon_hover}
          secondary
        />
      );
    });
  };

  const getExpandText = () => {
    if (expanded) {
      return null;
    }
    const expandTextClass = cx(
      'f18 mt24 flex items-center red self-start absolute pointer left-0',
      styles.expandText,
    );

    return (
      <div className={expandTextClass} onClick={toggleExpanded}>
        <span className="fw6 mr8">Ver todas as categorias</span>
        <Icon name="SvgIconArrowDownBold" width={12} height={12} />
      </div>
    );
  };

  const cardsWrapperClass = cx('pt32 ph16 bg-wild-sand flex flex-wrap', {
    pb32: expanded,
    pb72: !expanded,
  });

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <>
      <Topbar />
      <div className="tc bg-white pt32 pb40 ph28 flex flex-column">
        <h3 className="ma0 fw7 f20 f24-ns f30-m f30-l abbey">CADASTRO DE VEÍCULOS</h3>
        <p className="fw3 f14 abbey mt8 f16-ns f18-l mb0 ph32">
          Que tipo de veículo você está anunciando?
        </p>
      </div>
      <div className={cardsWrapperClass}>
        <div className="flex-ns w-100 justify-center">
          <div className="flex-ns relative">
            {getFixedCards()}
            {getExpandText()}
          </div>
        </div>
        <Collapse isOpened={expanded} className="w-100">
          <div className="pt16 pt32-ns flex-ns justify-center">{getCollapsedCards()}</div>
        </Collapse>
      </div>
      <Footer footerType="privacyPolicy" />
    </>
  );
};

export const SelectAdType = () => {
  const categoriesQuery = graphql`
    query {
      allDirectusCategoria(sort: { fields: sort }) {
        nodes {
          icon_hover
          icon
          name
        }
      }
    }
  `;

  const toRender = (data: DirectusCategoria) => {
    const {
      allDirectusCategoria: { nodes },
    } = data;
    return <RenderSelectAdType data={nodes} />;
  };

  return <StaticQuery query={categoriesQuery} render={toRender} />;
};
