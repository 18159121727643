import React from 'react';
import cx from 'classnames';
import { callAll } from '../../utils/callAll';
import { OutlineButton } from '../Button';
import { PrimaryButton } from '../Button/Primary';
import { GridCell } from '../GridCell';
import { Icon } from '../Icons';
import { StyledModal } from '../StyledModal';
import style from './ModalSuccesAndError.module.css';

export const modalSuccesDefault = {
  success: true,
  title: 'Parabéns!',
  subTitle: 'Sua ação foi concluída.',
};

export const modalErrorDefault = {
  success: false,
  title: 'Ops!',
  subTitle: 'ocorreu um erro inesperado',
};

interface Props {
  subTitle?: string;
  textBtnClose?: string;
  textBtnSecondary?: string;
  text?: string;
  title?: string;
  isOpen: boolean;
  success?: boolean;
  actionBtnSecondary?: () => void;
  closeModal: () => void;
  actionBtn?: (value?: any) => void;
  className?: string;
  primaryActionTitle?: string;
  secondaryActionTitle?: string;
}

export const ModalSuccessAndError = ({
  subTitle,
  textBtnClose = 'OK',
  textBtnSecondary,
  text,
  title,
  isOpen,
  success,
  actionBtnSecondary,
  closeModal,
  actionBtn,
  className,
  primaryActionTitle,
  secondaryActionTitle,
}: Props) => {
  const textRender = text ? (
    <span className="f16 abbey fw3 db pb32 pt8 pb32-ns">{text}</span>
  ) : (
    <span className="mt32" />
  );
  const renderButtonSecondary = () => {
    if (textBtnSecondary) {
      return (
        <OutlineButton
          className="mt16 ml16-ns"
          onClick={actionBtnSecondary}
          title={secondaryActionTitle}
          aria-label={secondaryActionTitle}
        >
          {textBtnSecondary}
        </OutlineButton>
      );
    }
  };
  const renderSvg = () => {
    const icon = success ? 'SvgIconSuccess' : 'SvgIconError';
    return <Icon className="center-ns" name={icon} height={120} width={120} />;
  };
  const modalClass = cx(style.modalClass, className);
  const descriptionClass = cx('f26 fw7 ma0 pt0', {
    'grass-green': success,
    red: !success,
  });

  return (
    <StyledModal isOpen={isOpen} closeModal={closeModal} className={modalClass} title={title}>
      <div className="pb48 pb32-ns pt48-ns ph32 flex flex-column h-100 justify-end ">
        {renderSvg()}
        <h1 className="f26 abbey fw2 mb0 mt32 mt12-ns" aria-label={title}>
          {title}
        </h1>
        <h2 className={descriptionClass} aria-label={subTitle}>
          {subTitle}
        </h2>
        {textRender}
        <GridCell className="flex-ns items-end-ns justify-center pb8 pb0-ns">
          <PrimaryButton
            resetClass
            classButton="ph32 f18 fw7 ttu"
            onClick={callAll(closeModal, actionBtn)}
            title={primaryActionTitle}
            aria-label={primaryActionTitle}
          >
            {textBtnClose}
          </PrimaryButton>
          {renderButtonSecondary()}
        </GridCell>
      </div>
    </StyledModal>
  );
};
