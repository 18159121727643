import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.css';

interface Props {
  icon: React.ReactNode;
  className?: string;
}

export const WithIcon: React.FC<
  Props & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, className, icon, ...props }) => {
  const classes = classnames(
    styles.withIcon,
    'bg-transparent bn f14 pa4 pointer red trueno no-underline underline-hover',
    className,
  );

  return (
    <button className={classes} {...props}>
      <span className="flex items-center">
        {icon && icon}
        <span className="mh8 fw4">{children}</span>
      </span>
    </button>
  );
};
