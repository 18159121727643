import React, { useState } from 'react';
import cx from 'classnames';
import { OutlineButton } from '../../Button';
import { Icon } from '../../Icons';
import styles from '../style.module.css';

interface Props {
  icon: string;
  iconHover: string;
  name: string;
  secondary?: boolean;
  onClick?: () => void;
}

export const CardItem = ({ name, icon, iconHover, secondary, onClick }: Props) => {
  const [hover, setHover] = useState(false);

  const currentIcon = hover ? iconHover : icon;
  const cardClass = cx(
    'flex flex-column-ns items-center-ns justify-end-ns',
    'mt16 mt0-ns pa8 bg-white br8',
    styles.mainCard,
    {
      'pa24-ns pv24-m ph40-m': !secondary,
      [styles.primarySize]: !secondary,
      'pa12-ns pa24-m': secondary,
      [styles.secondarySize]: secondary,
    },
  );
  const iconWrapperClass = cx(
    'pl16 pl0-ns flex justify-center w-50 w-100-ns mr16 mr0-ns',
    styles.iconWrapper,
  );
  const iconClass = cx('mw-100', styles.iconWrapper);

  const mouseEntered = () => {
    setHover(true);
  };
  const mouseLeaved = () => {
    setHover(false);
  };

  return (
    <div className={cardClass} onMouseEnter={mouseEntered} onMouseLeave={mouseLeaved}>
      <div className={iconWrapperClass}>
        <Icon name={currentIcon} className={iconClass} />
      </div>
      <div className="flex-auto flex-grow-0-ns w-100-ns flex flex-column ml4 ml0-ns justify-end">
        <p className="abbey f14 fw7 ttu tc-ns mv16-ns f18-ns f24-m f24-l">{name}</p>
        <OutlineButton
          onClick={onClick}
          size="medium"
          resetPadding
          className="mt4 mt0-ns f18-m f18-l"
        >
          ANUNCIAR
        </OutlineButton>
      </div>
    </div>
  );
};
