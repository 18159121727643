import React, { useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { GridCell } from '../GridCell';
import { Icon } from '../Icons';
import { ModalSuccessAndError } from '../ModalSuccessAndError';
import styles from './styles.module.css';

export const Topbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const topBarMainClass = cx('w-100 flex items-center', styles.topbar);
  const closeModal = () => setIsModalOpen(!isModalOpen);
  const goBack = () => navigate('/meus-anuncios');
  return (
    <header className={topBarMainClass}>
      <GridCell width={[1, 730, 970, 1280]} className="flex items-center justify-between center">
        <a href="/" className="pointer ttu no-underline" title="Voltar para a página inicial">
          <Icon name="SvgIconLogoWhite" className="ml16 ml0-ns" height={38} />
        </a>
        <button
          className="bn bg-transparent pointer white ttu underline f14 mr16 fw7"
          onClick={closeModal}
          title="Descartar e sair"
          aria-label="Descartar e sair"
        >
          Descartar e Sair
        </button>
      </GridCell>
      <ModalSuccessAndError
        textBtnClose="Sim"
        textBtnSecondary="Não"
        actionBtn={goBack}
        actionBtnSecondary={closeModal}
        isOpen={isModalOpen}
        subTitle="Deseja realmente sair?"
        closeModal={closeModal}
      />
    </header>
  );
};
