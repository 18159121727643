import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './Button.module.css';

interface Props {
  id?: any;
  iconName: string;
  iconWidth: number;
  customClass?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const IconButton = ({ id, iconName, iconWidth, customClass, onClick }: Props) => {
  const buttonClasses = cx(customClass, styles.iconButton);

  return (
    <button id={id} className={buttonClasses} onClick={onClick}>
      <Icon width={iconWidth} name={iconName} />
    </button>
  );
};
