import React from 'react';
import classnames from 'classnames';

interface Props {
  leftIcon?: React.ReactNode;
  state: 'on' | 'off';
  rightIcon?: {
    on: React.ReactNode;
    off: React.ReactNode;
  };
  className?: string;
}

export const TogglerButton: React.FC<
  Props & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, className, leftIcon, rightIcon, state, ...props }) => {
  return (
    <button
      className={classnames('bg-transparent bn f14 pa4 pointer trueno', className)}
      {...props}
    >
      <span className="flex items-center">
        {leftIcon && leftIcon}
        <span className="mh8">{children}</span>
        <span className="ml-auto">
          {rightIcon && state === 'on' && rightIcon.on}
          {rightIcon && state === 'off' && rightIcon.off}
        </span>
      </span>
    </button>
  );
};

TogglerButton.defaultProps = {
  state: 'off',
};
