import React from 'react';
import cx from 'classnames';
import Lottie from 'react-lottie';
import loader from '../Animation/loader.json';
import { Icon } from '../Icons';
import styles from './Button.module.css';

interface Props {
  children: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  classButton?: string;
  size?: string;
  iconLeft?: string;
  iconRight?: string;
  iconRightClass?: string;
  iconLeftClass?: string;
  disabled?: boolean;
  loading?: boolean;
  green?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
};

export const PrimaryWithIcon = ({
  children,
  loading = false,
  disabled = false,
  type,
  classButton,
  onClick,
  size,
  iconLeft,
  iconRight,
  iconRightClass,
  green,
  iconLeftClass,
  title,
  ...props
}: Props) => {
  const isDisabled = disabled ? disabled : loading;
  const handleBtn = loading ? <Lottie options={defaultOptions} height={28} width={28} /> : children;
  const buttonClasses = cx(
    'f16 fw4 br3 justify-between ph16 bn w-100 flex items-center',
    {
      'gradient-torch-red red-shadow-hover white pointer': !isDisabled,
      'boulder bg-alto not-allowed': disabled,
      'gradient-torch-red not-allowed': loading,
      'gradient-green-grass': green,
      pv12: !size,
      [styles.h48]: !size,
      pv8: size === 'medium',
      [styles.h40]: size === 'medium',
    },
    classButton,
  );
  const renderIconLeft = () => {
    if (!iconLeft) {
      return null;
    }
    return (
      <Icon
        name={iconLeft}
        width={16}
        height={16}
        className={iconLeftClass}
        customId={`${iconLeft}${title}`}
      />
    );
  };
  const renderIconRight = () => {
    if (!iconRight) {
      return null;
    }
    return (
      <Icon
        name={iconRight}
        width={12}
        height={12}
        className={iconRightClass}
        customId={`${iconRight}${title}`}
      />
    );
  };
  return (
    <button
      type={type}
      className={buttonClasses}
      disabled={isDisabled}
      {...props}
      onClick={onClick}
    >
      {renderIconLeft()}
      <span>{handleBtn}</span>
      {renderIconRight()}
    </button>
  );
};
